<template class="battlepass-level-card__wrapper">
  <div :class="['battlepass-level-card', cardClass]">
    <MainPageBattlepassLevelCardImageBlock :state="state" :variant="imageBlockVariant" />
    <MainPageBattlepassRewardBlock v-if="infoBlockVariant" :variant="infoBlockVariant" />
    <MainPageBattlepassLevelCardStatusBlock
      :event-data="eventData.button"
      :variant="statusBlockVariant"
      @claim="$emit('claim')"
    />
    <div class="battlepass-level-card__divider"></div>
  </div>
</template>

<script setup lang="ts">
import { EImageTypes, type TImageBlockVariants } from './ImageBlock/ImageBlock.types';
import type { TStatusBlockVariants } from './StatusBlock/StatusBlock.types';
import type { IBattlepassLevelCardEmits, IBattlepassLevelCardProps } from './BattlepassLevelCard.types';
import {
  EBattlepassRewardTypes,
  type TBattlepassRewardVariants,
} from '~/features/mainPage/components/BattlepassRewardBlock/BattlepassRewardBlock.types';
import type { TPossibleNull } from '~/types/Shared.types';
import { EBattlepassLevelState, EBattlepassLevelType } from '~/features/mainPage/Types/battlepass/client.types';

const { getBackgroundColor } = GlobalUtils.CSS;

defineEmits<IBattlepassLevelCardEmits>();
const props = defineProps<IBattlepassLevelCardProps>();

const cardClass = computed(() => ({
  'battlepass-level-card-opacity':
    props.state === EBattlepassLevelState.LOCKED || props.state === EBattlepassLevelState.RECEIVED,
}));

const imageBlockVariant = computed<TImageBlockVariants>(() => {
  if (props.caseData) {
    return {
      amount: props.caseData.amount,
      img: props.caseData.img,
      type: EImageTypes.CASE,
    };
  }

  if (props.balanceData?.coins) {
    return {
      icon: props.eventData.taskCoinIcon,
      type: EImageTypes.COINS,
    };
  }

  return { type: EImageTypes.MONEY };
});

const infoBlockVariant = computed<TPossibleNull<TBattlepassRewardVariants>>(() => {
  if (props.caseData) {
    return {
      amount: props.caseData.amount,
      link: props.caseData.link,
      name: props.caseData.name,
      type: EBattlepassRewardTypes.CASE,
    };
  }

  if (props.balanceData?.money) {
    return {
      money: props.balanceData.money,
      type: EBattlepassRewardTypes.MONEY,
    };
  }

  if (props.balanceData?.coins) {
    return {
      coins: props.balanceData.coins,
      color: props.eventData.color,
      icon: props.eventData.icon,
      type: EBattlepassRewardTypes.COINS,
    };
  }

  return null;
});

const statusBlockVariant = computed<TStatusBlockVariants>(() => {
  if (props.state === EBattlepassLevelState.CURRENT) {
    return {
      color: props.eventData.color,
      currentCoins: props.currentCoins,
      icon: props.eventData.icon,
      neededCoins: props.cost,
      state: props.state,
    };
  }

  if (props.state === EBattlepassLevelState.LOCKED) {
    return {
      level: props.level,
      state: props.state,
      type: props.type,
    };
  }

  if (props.state === EBattlepassLevelState.DONE) {
    return {
      claiming: props.claiming,
      level: props.level,
      state: props.state,
    };
  }

  return {
    level: props.level,
    state: props.state,
  };
});

const styleVariables = computed(() => {
  let cardShadow = 'transparent';
  let cardBackground = 'var(--battlepass-classic-locked)';

  if (props.type === EBattlepassLevelType.CLASSIC) {
    if (props.state === EBattlepassLevelState.CURRENT) {
      cardShadow = props.eventData.color;
      cardBackground = getBackgroundColor(props.eventData.gradient);
    }

    if (props.state === EBattlepassLevelState.DONE || props.state === EBattlepassLevelState.RECEIVED) {
      cardBackground = 'var(--battlepass-classic-done)';
    }

    return {
      cardBackground,
      cardShadow,
    };
  }

  cardBackground = `var(--battlepass-${props.type}-default)`;

  if (props.state === EBattlepassLevelState.CURRENT) {
    cardShadow = `var(--battlepass-${props.type}-shadow)`;
  }

  if (props.state === EBattlepassLevelState.CURRENT || props.state === EBattlepassLevelState.DONE) {
    cardBackground = `var(--battlepass-${props.type}-current)`;
  }

  return {
    cardBackground,
    cardShadow,
  };
});
</script>

<style scoped lang="scss">
.battlepass-level-card {
  --card-background: v-bind('styleVariables.cardBackground');
  --card-shadow: v-bind('styleVariables.cardShadow');
}
</style>

<style scoped lang="scss" src="./BattlepassLevelCard.scss" />
