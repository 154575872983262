<template>
  <div class="battlepass-event-point">
    <UiImage v-if="img" :src="img" :width="changeXL(48, 32)" :height="changeXL(48, 32)" loading="lazy" />
    <div class="battlepass-event-point__text">
      <div class="battlepass-event-point__text-header">
        <span class="battlepass-event-point__text-header__span">
          {{ title }}
        </span>
        <SharedKitTooltip v-if="$slots.tooltip" position="bottom">
          <slot name="tooltip" />
        </SharedKitTooltip>
      </div>
      <span class="battlepass-event-point__text-desc">
        <slot name="description">
          {{ $t('mainPage.battlepassEventPoint.levelsRemaining') }}
          <span class="battlepass-event-point__text-level">{{ remainingLevels ?? 0 }}</span>
        </slot>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IBattlepassEventPointProps } from './BattlepassEventPoint.types';

const props = withDefaults(defineProps<IBattlepassEventPointProps>(), {
  remainingLevels: 0,
});

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const styleVariables = computed(() => ({
  titleColor: props.color,
}));
</script>

<style scoped lang="scss">
.battlepass-event-point {
  --title-color: v-bind('styleVariables.titleColor');
}
</style>

<style scoped lang="scss" src="./BattlepassEventPoint.scss" />
