<template>
  <div class="image-block">
    <SharedMultiplier
      v-if="variant.type === EImageTypes.CASE && variant.amount > 1"
      class="image-block__multiplier"
      :multiplier="variant.amount"
    />
    <UiImage
      v-if="image"
      :class="['image-block__img', imageClass]"
      :src="image"
      :width="changeXL(144, 96)"
      :height="changeXL(144, 96)"
      loading="lazy"
    />
    <component :is="stateIcon" v-if="stateIcon" class="image-block__icon" />
  </div>
</template>

<script setup lang="ts">
import { EImageTypes, type IImageBlockProps } from './ImageBlock.types';
import { EBattlepassLevelState } from '~/features/mainPage/Types/battlepass/client.types';

const eventRubleImage = '/static/main-page/event-ruble.png';

const props = defineProps<IImageBlockProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const image = computed(() => {
  if (props.variant.type === EImageTypes.CASE) return props.variant.img;
  if (props.variant.type === EImageTypes.COINS) return props.variant.icon;
  return eventRubleImage;
});

const imageClass = computed(() => ({
  'image-block__img-received': props.state === EBattlepassLevelState.RECEIVED,
}));

const stateIcon = computed(() => {
  const icons = {
    current: '',
    done: 'LazySvgoDoneIcon',
    locked: 'LazySvgoLockIcon',
    received: 'LazySvgoReceivedIcon',
  };

  return icons[props.state];
});
</script>

<style scoped lang="scss" src="./ImageBlock.scss" />
